.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.nav-header {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  width: 209px;
  height: 20px;
  left: 77px;
  top: 81px;
  line-height: 20px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.nav-label {
  color: #ffffff;
  margin: 10px;
  font-weight: bold;
}

.nav-button {
  width: 84px;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

.content-button {
  width: 150px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 8px;
}

.content-button:hover,
.content-button:active,
.content-button:focus,
.content-button.active {
  background: none;
  color: #ffffff;
  border-color: none;
}

.btn-outline {
  color: #ffffff;
  border-color: #ffffff;
  letter-spacing: 0.05em;
}

.btn-outline:hover,
.btn-outline:active,
.btn-outline:focus,
.btn-outline.active {
  background: #ffffff;
  color: #000000;
  border-color: #ffffff;
}

.label-font {
  color: #ffffff;
}

.message-img {
  box-sizing: border-box;
  width: 250px;
  height: 350px;
  border: 2px solid #ffffff;
  border-radius: 0px 100px;
}

.main-content {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  text-align: center;
  color: #ffffff;
  margin-top: 10%;
}

.main-content2 {
  font-family: "Ribeye Marrow";
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.background-container {
  background-color: #1d232f;
}

.background-container-quotes {
  background: rgba(224, 198, 174, 0.5);
}

.card-background-container {
  background-color: #ffffff;
}

.empty-space {
  height: 70px;
  background-color: #ffffff;
}

.card-property {
  box-sizing: border-box;
  width: 260px;
  height: 297px;
  border: 1px solid rgba(0, 0, 0, 0.21);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  border-radius: 20px;
  margin-top: 30%;
  margin-bottom: 30%;
}

.card-content {
  width: 234px;
  height: 185px;
  font-family: "Mukta";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.video-bottom-label {
  color: #ffffff;
  margin-bottom: 10%;
}

.video-content {
  box-sizing: border-box;
  width: fit-content;
  height: 300px;
  background: rgba(255, 255, 255, 0.88);
  box-shadow: 0px 0px 50px 10px rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  margin-top: 20%;
}

.video-text {
  margin-top: 7%;
}

.video-text-content {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 60px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.video-text-content2 {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.quotes-content {
  font-family: "Ribeye Marrow";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 66px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  margin-top: 20%;
  margin-bottom: 20%;
}

.quote-content-text {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 60px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  margin-top: 15%;
}

.contact {
  margin-bottom: 3rem;
}

.about {
  margin-top: 3rem;
}

.carousel-indicators {
  bottom: -50px;
  margin-right: 50%;
}

.text-invisible {
  display: none;
}

.video-label {
  margin-top: 3rem;
}

.quote-space {
  margin-bottom: 1.5rem;
}

.footer-decoration {
  text-decoration: none;
  color: "white";
}

@media (max-width: 450px) {
  .footer-logo {
    display: flex;
    justify-content: center !important;
  }

  .video-label {
    margin-top: 1rem;
  }

  .carousel-indicators {
    bottom: -50px;
    margin-right: 20%;
  }

  .video-text {
    margin-top: 0%;
  }

  .carousel-responsive {
    display: flex;
    justify-content: center;
  }

  .text-size {
    font-size: 1rem !important;
  }

  .nav-header {
    font-family: "Mukta";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    width: 209px;
    height: 20px;
    left: 77px;
    top: 81px;
    line-height: 20px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }

  .video-content {
    box-sizing: border-box;
    width: 350px;
    height: 197px;
    background: rgba(255, 255, 255, 0.88);
    box-shadow: 0px 0px 50px 10px rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    margin-top: 10%;
  }

  .video-text-content {
    font-family: "Mukta";
    font-style: normal;
    font-weight: 700;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }

  .video-text-content2 {
    font-family: "Mukta";
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }

  .card-property {
    box-sizing: border-box;
    width: 260px;
    height: 297px;
    border: 1px solid rgba(0, 0, 0, 0.21);
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
    border-radius: 20px;
    margin-top: 8%;
    margin-bottom: 8%;
  }

  .quotes-content {
    font-family: "Ribeye Marrow";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 66px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    margin-top: 10%;
    margin-bottom: 0%;
  }

  .quote-content-text {
    font-family: "Mukta";
    font-style: normal;
    font-weight: 500;
    line-height: 3rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
  }

  .empty-space {
    height: 50px;
    background-color: #ffffff;
  }

  .contact {
    margin-bottom: 0rem;
  }

  .quote-space {
    margin-bottom: 0.5rem;
  }

  .about {
    margin-top: 1rem;
  }

  .main-content {
    font-family: "Mukta";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    display: flex;
    text-align: center;
    color: #ffffff;
    margin-top: 0%;
  }
  .contact-us {
    justify-content: start !important;
    padding-left: 10%;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
